






























































































































































































































































// @ is an alias to /src
import { IFetchInteractionsParams } from '@/store/modules/interactions';
import { ISessionParams } from '@/models/session';
import { IProductInteractionsParams } from '@/store/modules/productInteractions';
import SessionCard from '@/partials/SessionCard.vue';
import store from '@/store';
import { IFavoriteParams } from '@/models/visitor';
import { getFavorites } from '@/api/visitors';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    SessionCard,
  },
  data() {
    return {
      event: 0,
      exhibitor: 0,
      favoritesCount: null as null | number,
    };
  },
  setup() {
    const { translations, contextExhibitor, contextEvent } = useContext();
    const sessions = computed(() => store.getters.getSessions);
    const interactions = computed(() => store.getters.getInteractions);
    const productInteractions = computed(() => store.getters.getProductInteractions);
    const interactionsState = computed(() => store.getters.getInteractionsState);
    const productInteractionsState = computed(() => store.getters.getProductInteractionsState);

    return {
      translations,
      contextExhibitor,
      contextEvent,
      sessions,
      interactions,
      productInteractions,
      interactionsState,
      productInteractionsState,
    };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    const favoritesParams: IFavoriteParams = {
      source_type: 'ex',
      source_id: this.exhibitor,
    };
    getFavorites(favoritesParams).then((response) => {
      this.favoritesCount = response.data.item_count;
    });
    const interactionsParams: IFetchInteractionsParams = {
      exhibitor: this.exhibitor,
      page: 1,
      page_size: 1000,
    };
    const productInteractionsParams: IProductInteractionsParams = {
      event: this.event,
      page: 1,
      page_size: 1000,
      exhibitor: this.exhibitor,
    };
    const sessionsParams: ISessionParams = {
      event: this.event,
      exhibitor: this.exhibitor,
      type: 'exhibitor',
      page: 1,
      page_size: 6,
    };
    store.dispatch('fetchSessions', sessionsParams);
    store.dispatch('fetchInteractions', interactionsParams);
    store.dispatch('fetchProductInteractions', productInteractionsParams);
  },
});
